//= ../../bower_components/jquery/dist/jquery.min.js
//= ../../bower_components/fastclick/fastclick.js
//= ../../bower_components/modernizr.js
//= ../../bower_components/lazyload/lazyload.js
//= ../../bower_components/iCheck/icheck.min.js
//= ../../bower_components/jquery-validation/dist/jquery.validate.min.js
//= ../../bower_components/inputmask/dist/jquery.inputmask.bundle.js
//= ../../bower_components/inputmask/dist/inputmask/phone-codes/phone-ru.js
//= ../../bower_components/IsInViewport/isInViewport.min.js
//= ../../bower_components/fancybox/dist/jquery.fancybox.js
//= ../../bower_components/fotorama/fotorama.js
//= ../../bower_components/js-cookie/src/js.cookie.js
//= ../../bower_components/Swiper/swiper.min.js

//= script.js